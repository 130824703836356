<template>
  <div
    class="card-content margin-content detail-content page-contact"
    style="padding-bottom: 30px"
  >
    <CCard style="border: none">
      <div class="dc-header mb-4">
        <b-container fluid class="d-flex align-items-center font-title">
          <router-link
            :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
          >
            <div>ショップ</div>
          </router-link>
          <b-icon icon="chevronRight" class="mx-3"></b-icon>
          <router-link :to="{ name: '' }">
            <div>お問合せ</div>
          </router-link>
        </b-container>
      </div>
      <div class="h2 mb-3 font-title">お問い合わせ</div>
      <CCardBody>
        <div v-if="!isCreateSuccess" class="contact-table">
          <CRow>
            <CCol sm="12">
              <hr />
            </CCol>
            <CCol sm="4">
              <label>ご連絡先メールアドレス</label>
            </CCol>
            <CCol sm="8">
              <CInput class="input-profile" v-model="inquiry.email" disabled />
            </CCol>
            <CCol sm="12">
              <hr />
            </CCol>
            <CCol sm="4">
              <label>氏名</label>
            </CCol>
            <CCol sm="8">
              <CInput class="input-profile" v-model="inquiry.name" disabled />
            </CCol>
            <CCol sm="12">
              <hr />
            </CCol>
            <CCol sm="4">
              <label>問い合わせ内容</label>
            </CCol>
            <CCol sm="8">
              <CTextarea
                class="input-profile"
                v-model="inquiry.message"
                rows="10"
              />
            </CCol>
            <CCol sm="12">
              <hr />
            </CCol>
            <div
              v-for="(value, index) in inquiry.column"
              :key="index"
              class="w-100"
              style="padding: 15px"
            >
              <CRow>
                <CCol sm="4">
                  <label>{{ value.form.label }}</label>
                </CCol>
                <CCol sm="8">
                  <CInput
                    v-if="value.type === 1"
                    class="input-profile"
                    v-model="value.form.value"
                  />
                  <CTextarea
                    v-if="value.type === 2"
                    class="input-profile"
                    v-model="value.value"
                    rows="10"
                  ></CTextarea>
                  <CSelect
                    v-if="value.type === 3"
                    class="input-profile"
                    v-model="value.form.value"
                    :options="value.form.options"
                  ></CSelect>
                  <div class="d-flex">
                    <b-form-radio-group
                      v-if="value.type === 4"
                      v-model="value.form.value"
                      :options="value.form.options"
                      :name="value.form.name"
                    ></b-form-radio-group>
                  </div>
                  <div class="d-flex">
                    <b-form-checkbox-group
                      v-if="value.type === 5"
                      v-model="value.form.value"
                      :options="value.form.options"
                      :name="value.form.name"
                    ></b-form-checkbox-group>
                  </div>
                </CCol>
                <CCol sm="12">
                  <hr />
                </CCol>
              </CRow>
            </div>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center pc-only">
                <button
                  v-on:click.prevent="createForm()"
                  :size="'lg'"
                  class="mt-3 mr-3 btn-inquiry"
                >
                  ご相談を送信
                </button>
              </div>
            </CCol>
            <CCol sm="12">
              <div class="d-flex justify-content-center sp-only">
                <button
                  v-on:click.prevent="createForm()"
                  :size="'lg'"
                  class="mt-3 mr-3 btn-inquiry"
                >
                  ご相談を送信<br />
                  <span>※確認画面はございません。</span>
                </button>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center mt-3 pc-only">
                ※確認画面はございません。
              </div>
            </CCol>
          </CRow>
        </div>
        <div class="version-pc" v-if="isCreateSuccess">
          <p>ご相談内容を送信しました。</p>
          <p>回答までもうしばらくお待ちくださいませ。</p>
        </div>
        <div class="version-sp inquiry-success" v-if="isCreateSuccess">
          <p>ご相談内容を送信しました。</p>
          <p>回答までもうしばらくお待ちくださいませ。</p>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import Vue from "vue";
import Toasted from "vue-toasted";
Vue.use(Toasted);
export default {
  name: "CreateUser",

  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      inquiry: {
        email: "",
        name: "",
        message: "",
        column: "",
      },
      isCreateSuccess: false,
    };
  },
  created() {
    const formData = {
      shop_id: this.shop_id,
      user_type: localStorage.getItem(Constants.USER_TYPE_USER),
    };
    this.getProfile(formData);
    this.getFormInquiry(this.shop_id);
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "formInquiry",
      "userProfile",
    ]),
  },
  watch: {
    formInquiry() {
      if (this.formInquiry) {
        this.inquiry.column = this.formInquiry.column;
        this.formInquiry.column.forEach((val, index) => {
          if (val.type == 3) {
            this.formInquiry.column[index].form.value =
              this.formInquiry.column[index].form.options[0].value;
          }
        });
      }
    },
    success() {
      if (this.success) {
        this.$toasted.clear();
        this.$toasted.success(this.message);
        this.isCreateSuccess = true;
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.clear();
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    userProfile() {
      if (Object.keys(this.userProfile).length) {
        const { first_name, last_name, email } = this.userProfile;
        let lname = last_name ? last_name : "";
        let fname = first_name ? first_name : "";
        this.inquiry.name = lname + fname;
        this.inquiry.email = email;
      }
    },
  },
  methods: {
    ...mapActions({ getFormInquiry: "getFormInquiry" }),
    ...mapActions({ getProfile: "getProfile" }),
    ...mapActions({ createFormInquiry: "createFormInquiry" }),
    createForm() {
      const request = {
        shop_id: this.shop_id,
        name: this.inquiry.name,
        email: this.inquiry.email,
        message: this.inquiry.message,
        column: this.inquiry.column,
      };
      const api = Api.userRequestServer;
      api
        .post(`/${Urls.INQUIRY}/${Urls.CREATE}`, request)
        .then((response) => {
          if (response.status == 401) {
            localStorage.removeItem(Constants.TOKEN_USER);
            localStorage.removeItem(Constants.USER_TYPE_USER);
            localStorage.removeItem(Constants.EXPIRES_AT_USER);
            localStorage.removeItem(Constants.USER_ID);
            localStorage.removeItem(Constants.EMAIL_USER);
            localStorage.removeItem("popupSend");
            localStorage.removeItem(Constants.NORMAL_USER_INFO);
            localStorage.removeItem(Constants.IS_REMEMBER_USER);
            this.$router.push({
              name: this.$router.currentRoute.params.shopId
                ? "login shop"
                : "login shop domain",
            });
          }
          const { data } = response;
          if (data.success) {
            Vue.toasted.clear();
            Vue.toasted.success(this.message);
            this.isCreateSuccess = true;
          } else {
            Vue.toasted.clear();
            Vue.toasted.error(data.message, {
              duration: 11000,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.input-profile {
  background: #f1f1f1;
  margin-bottom: 0 !important;
  input {
    background: #f1f1f1;
  }
  input:focus {
    background: #f1f1f1;
  }
  select {
    background: #f1f1f1;
  }
  select:focus {
    background: #f1f1f1;
  }
  textarea {
    background: #f1f1f1;
  }
  textarea:focus {
    background: #f1f1f1;
  }
}
.input-profile:focus {
  background: #f1f1f1;
}
.btn-inquiry {
  width: 220px;
  border: none;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  height: 50px;
  line-height: 38px;
  background-color: #1d1d1d;
  transition: background-color 0.4s ease;
  border-radius: 0px;
  color: #fff;
}
.inquiry-success {
  box-shadow: 0px 3px 6px #7681929c;
  border-radius: 10px;
  padding: 50px 20px;
}
.toasted-container.top-right .toasted {
  opacity: 0;
  animation: fadeCustom 11s linear !important;
  transition: transform 3.5s cubic-bezier(0.22, 1, 0.36, 1);
}
@keyframes fadeCustom {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
</style>
